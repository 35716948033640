import { useState } from "react"
import { Col, Container, Row } from "react-bootstrap";
//import contactImg from "../assets/img/contact-img.svg";

import '../i18n/index';
import {useTranslation} from 'react-i18next';

export const Contact = () => {
    const formInitialDetails = {

        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    }

    
    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Send');
    const [status, setStatus] = useState({});

    const {t, i18n} = useTranslation();

    const onFormUpdate = (category, value) => {
        setFormDetails ({
            ...formDetails,
           [ category]: value
        })
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonText("Enviando...");
        let response = await fetch("http://localhost:5000/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(formDetails),
        });
        setButtonText("Send");
        let result = await response.json();
        setFormDetails(formInitialDetails);
        if (result.code === 200) {
          setStatus({ succes: true, message: 'Mensagem enviada com Sucesso'});
        } else {
          setStatus({ succes: false, message: 'Algo deu errado, tente novamente mais tarde.'});
        }
      };

    return (

        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <h2>{t('formulario')}</h2>
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={6} className="px-1">
                                    <input type="text" value={formDetails.firstName} placeholder={t('primeiroNome')} onChange={(e)=> onFormUpdate('firstName', e.target.value)}/>
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input type="text" value={formDetails.lastName} placeholder={t('segundoNome')} onChange={(e)=> onFormUpdate('lastName', e.target.value)}/>
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input type="email" value={formDetails.email} placeholder={t('email')} onChange={(e)=> onFormUpdate('email', e.target.value)}/>
                                </Col>
                                <Col sm={6} className="px-1">
                                    <input type="tel" value={formDetails.phone} placeholder={t('telefone')} onChange={(e)=> onFormUpdate('phone', e.target.value)}/>
                                </Col>
                                <Col>
                                <textarea rows="6" value={formDetails.message} placeholder={t('mensagem')} onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                                <button type="submit"><span>{t('botaoEnviar')}</span></button>
                                </Col>
                                    {
                                        status.message &&
                                        <Col>
                                            <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                        </Col>
                                    }
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
    
  
}