import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/Site-Eletro.png";
import cineFilme from "../assets/img/CineFilme.png";
import calculadora from "../assets/img/Calculadora.png"
import imgDom from "../assets/img/CERTIFICADO DOM.jpg";
import imgCss from "../assets/img/CERTIFICADO CSS.jpg";
import imgHtml from "../assets/img/CERTIFICADO HTML.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import '../i18n/index';
import {useTranslation} from 'react-i18next';

export const Projects = () => {

  const {t, i18n} = useTranslation();
  const certificate = [   
    {
      title: t('certificadosDOM'),
      description: t('certificadosHoras'),
      hours: t('horasDom'),
      imgUrl: imgDom,
    },

    {
      title: t('certificadosCSS'),
      description: t('certificadosHoras'),
      hours: t('horasCss'),
      imgUrl: imgCss,
    },

    {
      title: t('certificadosHTML'),
      description: t('certificadosHoras'),
      hours: t('horasHtml'),
      imgUrl: imgHtml,
    }
  ];

  const projects = [
    {
      title: t('siteWordpress'),
      description: t('subtituloDesigner'),
      imgUrl: projImg1,
    },
    {
      title: t('calculadora'),
      description: t('subtituloDesigner'),
      imgUrl: calculadora,
    },
    {
      title: t('cineFilme'),
      description: t('subtituloDesigner'),
      imgUrl: cineFilme,
    },
    
  ];

  

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>{t('projetos')}</h2>
                <p></p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">{t('projetos')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">{t('academico')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">{t('certificado')}</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="fourth">{t('experiencia')}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          certificate.map((projectCertificate, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...projectCertificate}
                                />
                              )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <section>
                        <h3>{t('academicoTexto')}</h3>
                        <p>{t('formacaoAcademica')}</p>
                        <p>{t('turmaFaculdade')}</p>
                        <hr></hr>
                        <h3>SENAC</h3>
                        <p>{t('tecnico')}</p>
                        <p>{t('formacaoAcademica-02')}</p>
                      </section>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                     <section>
                      <h3>{t('desenvolvedorSistemas')}</h3>
                      <p>{t('experienciaLocal')}</p>
                      <p>{t('texto-01')}</p>
                      <p>{t('texto-02')}</p>
                      <hr></hr>
                      <h3>{t('analistaSistemas')}</h3>
                      <p>{t('experienciaLocal-01')}</p>
                      <p>{t('texto-03')}</p>
                      <p>{t('texto-04')}</p>
                      <p>{t('texto-05')}</p>
                     </section>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img alt="" className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}