import { Col, Container, Row } from "react-bootstrap";
import meter1 from "../assets/img/html.png";
import meter2 from "../assets/img/css-3.png";
import meter3 from "../assets/img/js.png";
import meter4 from "../assets/img/physics.png";
import meter5 from "../assets/img/typescript.png";
import meter6 from "../assets/img/express.png";
import meter7 from "../assets/img/node.png";
import meter8 from "../assets/img/php.png";
import meter9 from "../assets/img/github.png";
import meter10 from "../assets/img/MySql.png";
import meter11 from "../assets/img/bootstrap.png";
import meter12 from "../assets/img/wordpress.png";
import colorSharp from "../assets/img/color-sharp.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import '../i18n/index';
import {useTranslation} from 'react-i18next';

export const Skills = () => {
    const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 5
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1
            }
    };
    const {t, i18n} = useTranslation();

    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx">
                            <h2>{t('habilidades')}</h2>
                            <p>{t('textoHabilidades')}
                                <br></br>{t('textoHabilidades-02')}</p>
                                <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                    <div className="item">
                                        <img src={meter1} alt="Imagem "/>
                                        <h5>HTML</h5>
                                    </div>

                                    <div className="item">
                                        <img src={meter2} alt="Imagem "/>
                                        <h5>CSS</h5>
                                    </div>
                                    <div className="item">
                                        <img src={meter3} alt="Imagem "/>
                                        <h5>JavaScript</h5>
                                    </div>
                                    <div className="item">
                                        <img src={meter4} alt="Imagem "/>
                                        <h5>React</h5>
                                    </div>

                                    <div className="item">
                                        <img src={meter5} alt="Imagem "/>
                                        <h5>TypeScript</h5>
                                    </div>
                                    <div className="item">
                                        <img src={meter6} alt="Imagem "/>
                                        <h5>Express</h5>
                                    </div>

                                    <div className="item">
                                        <img src={meter7} alt="Imagem "/>
                                        <h5>Node-JS</h5>
                                    </div>
                                    <div className="item">
                                        <img src={meter8} alt="Imagem "/>
                                        <h5>PHP</h5>
                                    </div>
                                    <div className="item">
                                        <img src={meter9} alt="Imagem "/>
                                        <h5>Github/API's</h5>
                                    </div>
                                    <div className="item">
                                        <img src={meter10} alt="Imagem "/>
                                        <h5>MYSQL/PL-SQL</h5>
                                    </div>
                                    <div className="item">
                                        <img src={meter11} alt="Imagem "/>
                                        <h5>Bootstrap/Bulma</h5>
                                    </div>
                                    <div className="item">
                                        <img src={meter12} alt="Imagem "/>
                                        <h5>WordPress</h5>
                                    </div>
                                </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
                <img className="background-image-left" src={colorSharp} alt="..."/>
        </section>
    )
}