import { useState, useEffect} from "react";
import { Container, Row, Col, } from "react-bootstrap";

import headerImg from "../assets/img/voando.png";

import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import '../i18n/index'
import {useTranslation} from 'react-i18next'

import imgEnglish  from '../assets/img/us-EN.png';
import imgPortuguese from '../assets/img/pt-BR.png'




export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Developer Full Stack"];
  const period = 2000;

const {t, i18n} = useTranslation();

const handleChangeLng = (lng) => {
		i18n.changeLanguage(lng);
		localStorage.setItem("lng", lng);
	};
 

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return ( 
    
    <section className="banner" id="home">
      <Container>
        <div className="translation">          
            <button onClick={() => handleChangeLng("en")}><img src={imgEnglish} /> {t('botaoIngles')}</button>
            <button onClick={() => handleChangeLng("pt")}><img src={imgPortuguese} /> {t('botaoPortugues')}</button>          
        </div>

        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>                   
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">{t('bemVindo')}</span>
                <h1>{t('olaEuSou')} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Web Developer", "Web Designer"]'><span className="wrap">{text}</span></span></h1>
                    <p>{t('carreiraProgramador')}
                    <br></br> {t('carreiraProgramador-02')}                    
                    </p>
                      
                      

              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>                  
                  <img className="imgHeader" src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
            <button onClick={() => console.log('connect')}>Let’s Connect<ArrowRightCircle size={25} /></button>  
          </Col>
        </Row>
        
      </Container>
    </section>
  )
}

